<template>
    <DxToolbar :height="56" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <routerVue :breadcrumbsItems="breadcrumbsItems" />
            </template>
        </DxItem>
        <DxItem location="after" locate-in-menu="never">
            <template #default>
                <div>
                    <DxButton
                        key="mdi-HUY"
                        type="default"
                        text="HỦY"
                        styling-mode="text"
                        :width="100"
                        class="mr-4"
                        @click="$router.push('/Cong-Hoa-Don-Dien-Tu')"
                    /><DxButton
                        key="mdi-XACNHAN"
                        type="default"
                        text="LƯU"
                        :width="100"
                        styling-mode="contained"
                        @click="ThemSuaKyHieu()"
                    />
                </div>
            </template>
        </DxItem>
    </DxToolbar>
    <div class="row justify-center mt-4">
        <DxValidationGroup ref="formValidation">
            <div class="frame-box">
                <div class="row font-20 font-medium justify-center">
                    {{
                        this.$route.query && this.$route.query.guidKyHieu
                            ? "Sửa ký hiệu"
                            : "Thêm ký hiệu"
                    }}
                </div>
                <div class="row xs12">
                    <div class="xs5 mr-12">
                        <div class="row mt-3">
                            <div class="font-16 font-medium">
                                Thông tin chính
                            </div>
                            <div>
                                <DxCheckBox
                                    v-model="DuLieuThemSuaKyHieu.hoaDonCoMa"
                                    text="Hóa đơn có mã"
                                    class="ml-2"
                                />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="
                                        $Core.Auth.TokenParsed.TenDoanhNghiep
                                    "
                                    label="Tên doanh nghiệp (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    :readOnly="true"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Tên doanh nghiệp không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="xs12">
                                <DxSelectBox
                                    v-model="DuLieuThemSuaKyHieu.guidKieuHoaDon"
                                    label="Kiểu hóa đơn (*)"
                                    :data-source="DanhSachKieuHoaDon"
                                    display-expr="name"
                                    value-expr="id"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Kiểu hóa đơn không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="xs12">
                                <DxSelectBox
                                    v-model="DuLieuThemSuaKyHieu.guidLoaiHoaDon"
                                    label="Loại hóa đơn (*)"
                                    :data-source="DanhSachLoaiHoaDon"
                                    display-expr="tenLoaiHoaDon"
                                    value-expr="guidLoaiHoaDon"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                    :onItemClick="LoaiHoaDon"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Loại hóa đơn không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="DuLieuThemSuaKyHieu.mauSo"
                                    label="Mẫu số (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Mẫu số không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="
                                        DuLieuThemSuaKyHieu.phanTramGiamTru
                                    "
                                    label="Phần trăm giảm trừ (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Phần trăm giảm trừ không được bỏ trống!"
                                        />
                                        <DxPatternRule
                                            :pattern="/^[+-]?\d+(\.\d+)?$/"
                                            message="Phải là float"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="xs6 mr-2">
                                <DxTextBox
                                    v-model="DuLieuThemSuaKyHieu.kyHieu"
                                    label="Ký hiệu (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Ký hiệu không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                            <div class="xs6 ml-3">
                                <DxTextBox
                                    v-model="DuLieuThemSuaKyHieu.phanTramThue"
                                    label="Phần trăm thuế (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                    mode="tel"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Phần trăm thuế không được bỏ trống!"
                                        />
                                        <DxPatternRule
                                            :pattern="/^[+-]?\d+(\.\d+)?$/"
                                            message="Phải là float"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="xs6 mr-2">
                                <DxDateBox
                                    v-model="DuLieuThemSuaKyHieu.ngayBatDau"
                                    label="Ngày bắt đầu (*)"
                                    labelMode="floating"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="outlined"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Ngày bắt đầu không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="
                                                $i18n.t('DefaultString.MaxDate')
                                            "
                                        />
                                        <DxRangeRule
                                            :min="MinDate"
                                            :message="
                                                $i18n.t('DefaultString.MinDate')
                                            "
                                        />
                                    </DxValidator>
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                            <div class="xs6 ml-3">
                                <DxDateBox
                                    v-model="DuLieuThemSuaKyHieu.ngayKetThuc"
                                    label="Ngày kết thúc (*)"
                                    labelMode="floating"
                                    displayFormat="dd/MM/yyyy"
                                    :use-mask-behavior="true"
                                    dropDownButtonTemplate="customIcon"
                                    validationMessageMode="always"
                                    styling-mode="outlined"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Ngày kết thúc không được bỏ trống!"
                                        />
                                        <DxRangeRule
                                            :max="MaxDate"
                                            :message="
                                                $i18n.t('DefaultString.MaxDate')
                                            "
                                        />
                                        <DxRangeRule
                                            :min="MinDate"
                                            :message="
                                                $i18n.t('DefaultString.MinDate')
                                            "
                                        />
                                    </DxValidator>
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="DuLieuThemSuaKyHieu.templateCode"
                                    label="Template Code (*)"
                                    labelMode="floating"
                                    styling-mode="outlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxRequiredRule
                                            message="Template Code không được bỏ trống!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>

                    <div class="xs7 ml-12">
                        <div class="row mt-3">
                            <div class="xs12">
                                <div class="shrink">
                                    <div class="font-16 font-medium">
                                        Loại đơn hàng áp dụng
                                    </div>
                                    <DxButton
                                        type="normal"
                                        styling-mode="text"
                                        icon="mdi mdi-plus"
                                        hint="Thêm dòng"
                                        @click="ThemDong()"
                                    />
                                </div>
                                <DxDataGrid
                                    class="table-page"
                                    :show-borders="true"
                                    :data-source="
                                        DuLieuThemSuaKyHieu.loaiDonHangAppDungs
                                    "
                                    :onRowValidating="
                                        onRowValidatingDsDiemDungDi
                                    "
                                    height="608px"
                                    ref="LoaiDonHangApDung"
                                >
                                    <DxEditing
                                        :allow-updating="true"
                                        :allow-adding="false"
                                        :allow-deleting="true"
                                        :select-text-on-edit-start="true"
                                        mode="cell"
                                    />
                                    <DxPaging :enabled="false" />

                                    <!-- <DxScrolling mode="virtual" /> -->
                                    <DxColumn
                                        :allowSorting="true"
                                        data-field="TenTuyen"
                                        caption="Tuyến vận chuyển"
                                        alignment="left"
                                        :setCellValue="
                                            changeValueRowTuyenVanChuyen
                                        "
                                        cell-template="frame-cell-template"
                                    >
                                        <DxLookup
                                            :data-source="DanhSachTuyenFilter"
                                            value-expr="IdDnvtTuyen"
                                            display-expr="TenTuyenMaTuyen"
                                        />
                                        <DxRequiredRule />
                                        <!-- <DxPatternRule
                                            :pattern="
                                                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                                            "
                                            message="Phải là Guid"
                                        /> -->
                                    </DxColumn>
                                    <template #frame-cell-template="{ data }">
                                        <div>
                                            {{
                                                data.data.TenTuyen +
                                                `${
                                                    data.data.MaTuyen
                                                        ? ` (${data.data.MaTuyen})`
                                                        : ""
                                                }`
                                            }}
                                        </div>
                                    </template>
                                </DxDataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DxValidationGroup>
    </div>

    <!-- <DataTableVue /> -->
</template>

<script>
import {
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxColumn,
    DxEditing,
    DxLookup,
} from "devextreme-vue/data-grid";
import { v4 as uuidv4 } from "uuid";
import { DxToolbar } from "devextreme-vue";
import routerVue from "@sonphat/common-v1/components/router";
// import DataTableVue from "./components/DataTable.vue";
import { DxItem } from "devextreme-vue/toolbar";
import {
    DxTextBox,
    DxSelectBox,
    DxValidator,
    DxValidationGroup,
    DxDateBox,
    DxButton,
    DxCheckBox,
} from "devextreme-vue";
import { DxPatternRule, DxRangeRule } from "devextreme-vue/validator";
import { DxRequiredRule } from "devextreme-vue/data-grid";
import { mapState } from "vuex";
export default {
    components: {
        DxLookup,
        DxDataGrid,
        DxEditing,
        DxPaging,
        DxScrolling,
        DxColumn,
        routerVue,
        DxToolbar,
        DxItem,
        DxLookup,
        DxPatternRule,

        DxRangeRule,
        DxRequiredRule,
        DxCheckBox,
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxValidationGroup,
        DxDateBox,
        DxButton,
    },
    data() {
        return {
            MaxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            MinDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            DuLieuThemSuaKyHieu: {
                guidCauHinh: "",
                guidLoaiHoaDon: "",
                guidKieuHoaDon: "",
                loaiHoaDon: "",
                mauSo: "",
                hoaDonCoMa: true,
                phanTramGiamTru: "",
                kyHieu: "",
                phanTramThue: "",
                ngayBatDau: new Date("January 01, 2022"),
                ngayKetThuc: new Date("December 31, 2032"),
                loaiDonHangAppDungs: [
                    {
                        IdDnvtTuyen: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        TenTuyen: "Tất cả",
                    },
                ],
                templateCode: "",
            },

            breadcrumbsItems: [
                {
                    id: this.$t("namePath.KeHoachHoatDong"),
                    text: "Cổng hóa đơn điện tử",
                    disabled: false,
                    to: "/Cong-Hoa-Don-Dien-Tu",
                },
                {
                    id: this.$t("namePath.QuanLyTuyenVanChuyen"),
                    text: "Thêm ký hiệu",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    async created() {
        let query = this.$route.query;
        await this.LayDanhSachTuyen();

        if (query && query.guidKyHieu) {
            this.breadcrumbsItems[1].text = "Sửa ký hiệu";
            this.LayDanhSachKyHieu();
        }
        this.LayDanhSachLoaiHoaDon();
        this.LayDanhSachKieuHoaDon();
    },
    mounted() {},
    computed: {
        ...mapState({
            DanhSachLoaiHoaDon: (state) =>
                state.CongHoaDonDienTu.DanhSachLoaiHoaDon,

            DanhSachKieuHoaDon: (state) =>
                state.CongHoaDonDienTu.DanhSachKieuHoaDon,
            DanhSachTuyen: (state) => state.CongHoaDonDienTu.DanhSachTuyen,
        }),
        DanhSachTuyenFilter() {
            let DanhSachTuyenLocal = [...this.DanhSachTuyen];
            let tmpDanhSachTuyen = [...this.DanhSachTuyen];
            this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs.forEach((e) => {
                tmpDanhSachTuyen.forEach((x) => {
                    if (e.IdDnvtTuyen == x.IdDnvtTuyen) {
                        DanhSachTuyenLocal = DanhSachTuyenLocal.filter(
                            (k) => k.IdDnvtTuyen != e.IdDnvtTuyen
                        );
                    }
                });
            });
            return DanhSachTuyenLocal;
        },
    },
    watch: {},
    methods: {
        async LayDanhSachTuyen() {
            let rs = await this.$store.dispatch(
                "CongHoaDonDienTu/LayDanhSachTuyen"
            );
            if (!rs.status) {
                this.ThongBao("error", `Lấy danh sách tuyến thất bại!`);
            }
        },
        changeValueRowTuyenVanChuyen(newData, value) {
            let selectData = this.DanhSachTuyen.filter(
                (x) => x.IdDnvtTuyen == value
            )[0];
            newData = Object.assign(newData, selectData);
        },
        async LayDanhSachKyHieu() {
            let rs = await this.$store.dispatch(
                "CongHoaDonDienTu/LayDanhSachKyHieu",
                {
                    guidBenXe: this.$Core.Auth.TokenParsed.GuidDoanhNghiep,
                }
            );
            if (!rs.status) {
                this.ThongBao("error", `Lấy chi tiết ký hiệu thất bại!`);
            } else {
                console.log(rs.data);
                rs.data.forEach((e) => {
                    if (e.guidKyHieu == this.$route.query.guidKyHieu) {
                        this.DuLieuThemSuaKyHieu.guidCauHinh = e.guidKyHieu;
                        this.DuLieuThemSuaKyHieu.guidLoaiHoaDon = e.loaiHoaDon;
                        this.DuLieuThemSuaKyHieu.loaiHoaDon = e.loaiHoaDon;
                        this.DuLieuThemSuaKyHieu.mauSo = e.mauSo;
                        this.DuLieuThemSuaKyHieu.kyHieu = e.kyHieu;
                        this.DuLieuThemSuaKyHieu.guidKieuHoaDon =
                            e.guidKieuHoaDon;
                        this.DuLieuThemSuaKyHieu.phanTramGiamTru =
                            e.phanTramGiamTru;
                        this.DuLieuThemSuaKyHieu.hoaDonCoMa = e.hoaDonCoMa;
                        this.DuLieuThemSuaKyHieu.phanTramThue = e.phanTramThue;
                        this.DuLieuThemSuaKyHieu.templateCode = e.templateCode;
                        this.DuLieuThemSuaKyHieu.ngayBatDau = new Date(
                            e.thoiGianBatDau
                        );
                        this.DuLieuThemSuaKyHieu.ngayKetThuc = new Date(
                            e.thoiGianKetThuc
                        );
                        this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs = [];
                        e.loaiDonHangApDungs.forEach((e) => {
                            let item = this.DanhSachTuyen.find(
                                (x) => x.IdDnvtTuyen == e
                            );
                            if (item) {
                                this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs.push(
                                    item
                                );
                            }
                        });
                        if (
                            this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs
                                .length == 0
                        ) {
                            this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs.push({
                                IdDnvtTuyen:
                                    "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                TenTuyen: "Tất cả",
                            });
                        }
                    }
                });
            }
        },
        ThongBao(type, message) {
            let modelToast = {
                isVisible: true,
                message: message,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        ThemDong() {
            this.$refs["LoaiDonHangApDung"].instance.cancelEditData();
            setTimeout(() => {
                this.$refs["LoaiDonHangApDung"].instance.addRow();
            }, 100);
        },

        async LayDanhSachLoaiHoaDon() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/LayDanhSachLoaiHoaDon"
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách loại hóa đơn thất bại! ${rs.message}`
                    );
                } else {
                }
            } catch (error) {
                this.ThongBao(
                    "error",
                    `Lấy danh sách loại hóa đơn thất bại! Lỗi hệ thống`
                );
                this.$stopLoading;
            }
        },

        async LayDanhSachKieuHoaDon() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/LayDanhSachKieuHoaDon"
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách kiểu hóa đơn thất bại! ${rs.message}`
                    );
                } else {
                }
            } catch (error) {
                this.ThongBao(
                    "error",
                    `Lấy danh sách kiểu hóa đơn thất bại! Lỗi hệ thống`
                );
                this.$stopLoading;
            }
        },

        LoaiHoaDon(e) {
            this.DuLieuThemSuaKyHieu.loaiHoaDon = e.itemData.tenLoaiHoaDon;
        },
        async ThemKyHieu() {
            try {
                let loaiDonHangAppDung = [];
                loaiDonHangAppDung =
                    this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs.map(
                        (e) => e.IdDnvtTuyen
                    );
                this.$startLoading;
                let DuLieuGuiDi = {
                    guidCauHinh: uuidv4(),
                    guidLoaiHoaDon: this.DuLieuThemSuaKyHieu.guidLoaiHoaDon,
                    loaiHoaDon: this.DuLieuThemSuaKyHieu.guidLoaiHoaDon,
                    mauSo: this.DuLieuThemSuaKyHieu.mauSo,
                    kyHieu: this.DuLieuThemSuaKyHieu.kyHieu,
                    phanTramThue: this.DuLieuThemSuaKyHieu.phanTramThue,
                    guidKieuHoaDon: this.DuLieuThemSuaKyHieu.guidKieuHoaDon,
                    phanTramGiamTru: this.DuLieuThemSuaKyHieu.phanTramGiamTru,
                    hoaDonCoMa: this.DuLieuThemSuaKyHieu.hoaDonCoMa,
                    templateCode: this.DuLieuThemSuaKyHieu.templateCode,

                    ngayBatDau: this.$Helper
                        .getStartDay(this.DuLieuThemSuaKyHieu.ngayBatDau)
                        .toISOString(),

                    ngayKetThuc: this.$Helper
                        .getStartDay(this.DuLieuThemSuaKyHieu.ngayKetThuc)
                        .toISOString(),
                    loaiDonHangAppDungs: loaiDonHangAppDung,
                };
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/ThemKyHieu",
                    DuLieuGuiDi
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Thêm ký hiệu thất bại thất bại ${rs.message}`
                    );
                } else {
                    this.ThongBao("success", `Thêm ký hiệu thành công!`);
                    this.$router.push("/Cong-Hoa-Don-Dien-Tu");
                }
            } catch (error) {
                console.log(error);
                this.$stopLoading;
            }
        },

        async SuaKyHieu() {
            try {
                let loaiDonHangAppDung = [];
                this.DuLieuThemSuaKyHieu.loaiDonHangAppDungs.forEach((e) => {
                    loaiDonHangAppDung.push(e.IdDnvtTuyen);
                });
                this.$startLoading;
                let DuLieuGuiDi = {
                    guidCauHinh: this.DuLieuThemSuaKyHieu.guidCauHinh,
                    guidLoaiHoaDon: this.DuLieuThemSuaKyHieu.guidLoaiHoaDon,
                    loaiHoaDon: this.DuLieuThemSuaKyHieu.guidLoaiHoaDon,
                    mauSo: this.DuLieuThemSuaKyHieu.mauSo,
                    kyHieu: this.DuLieuThemSuaKyHieu.kyHieu,
                    phanTramThue: this.DuLieuThemSuaKyHieu.phanTramThue,
                    guidKieuHoaDon: this.DuLieuThemSuaKyHieu.guidKieuHoaDon,
                    phanTramGiamTru: this.DuLieuThemSuaKyHieu.phanTramGiamTru,
                    hoaDonCoMa: this.DuLieuThemSuaKyHieu.hoaDonCoMa,
                    templateCode: this.DuLieuThemSuaKyHieu.templateCode,

                    ngayBatDau: this.$Helper
                        .getStartDay(this.DuLieuThemSuaKyHieu.ngayBatDau)
                        .toISOString(),

                    ngayKetThuc: this.$Helper
                        .getStartDay(this.DuLieuThemSuaKyHieu.ngayKetThuc)
                        .toISOString(),
                    loaiDonHangAppDungs: loaiDonHangAppDung,
                };
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/SuaKyHieu",
                    DuLieuGuiDi
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Sửa ký hiệu thất bại thất bại ${rs.message}`
                    );
                } else {
                    this.ThongBao("success", `Sửa ký hiệu thành công!`);
                    this.$router.push("/Cong-Hoa-Don-Dien-Tu");
                }
            } catch (error) {
                console.log(error);
                this.$stopLoading;
            }
        },

        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        ThemSuaKyHieu() {
            if (!this.validate().isValid) {
                this.$Helper.Common.autoFocusError(this.validate());
            } else {
                if (this.$route.query && this.$route.query.guidKyHieu) {
                    this.SuaKyHieu();
                } else {
                    this.ThemKyHieu();
                }
            }
        },
    },
    watch: {},
};
</script>

<style scoped>
.title {
    font-weight: 500;
    font-size: 16px;
}
.frame-box {
    width: 924px;
    border: 1px solid #dadce0;
    padding: 24px;
}
.shrink {
    display: flex;
    justify-content: space-between;
}
</style>
